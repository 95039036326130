/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import ChangePasswordForm from "../../common/components/ChangePasswordForm";
import Profile from "../../common/pages/Profile";
import CreateReceipt from "../../common/pages/CreateReceipt";
import User from "../pages/User";

const userRoutes = [
    {
		path: '/dashboard',
		component: <User />
	},
	{
		path: '/dashboard/add-receipt',
		component: <CreateReceipt />
	},
	{
		path: '/dashboard/edit/*',
		component: <CreateReceipt />
	},
	{
		path: '/profile',
		component: <Profile />
	},
	{
		path: '/profile/change-password',
		component: <ChangePasswordForm />
	}
]

export default userRoutes;