/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import DataTable from 'react-data-table-component';

const Table = ({ columns, data, conditionalStyle }) => {
	
	const conditionalRowStyles = [
		{
			when: (row) => !row.enabled,
			style: {
				background: 'rgba(169, 169, 169, 0.5)'
			}
		}
	];
	
	return (
		<div id='table'>
			<DataTable
				columns={columns}
				data={data}
				striped
				fixedHeader
				pagination
				customStyles={{
					cells: {
						style: {
							whiteSpace: 'nowrap',
							fontSize: '130%'
						}
					},
					headCells: {
						style: {
							fontSize: '140%'
						}
					}
				}}
				conditionalRowStyles={conditionalStyle ? conditionalRowStyles : undefined}
			/>
		</div>
	);
};

export default Table;