/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

const adminNavbar = [
	{
		name: 'Dashboard',
		path: '/dashboard'
	},
	{
		name: 'Company Profile',
		path: '/company-profile'
	},
	{
		name: 'Financial Report',
		path: '/financial-report'
	},
	{
		name: 'User Profile',
		path: '/profile'
	}
]

export default adminNavbar;