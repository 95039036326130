/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { ROLES,GENDER} from "../Variables";

function UserInfoCard({ user, onPasswordChangeClick, onEditClick }) {
	return (
		<div className='user-info-card' id='userProfile'>
			<h3 id='profileTitle'>Personal Information</h3>
			<br />
			<div id='profileDetails'>
				{user && (
					<>
						<p id='firstname'><strong>First Name:</strong> {user.firstName}</p>
						<p id='lastname'><strong>Last Name:</strong> {user.lastName}</p>
						<p id='email'><strong>Email:</strong> {user.email}</p>
						<p id='gender'><strong>Gender:</strong> {GENDER[user.gender]}</p>
						{ (user.role!=undefined) && <p id='role'><strong>Role:</strong> {(Object.values(ROLES).find(role => role.value === user.role)).name}</p>}
					</>
				)}
				{!user && <p id='loadingMessage'>Loading user information...</p>}
			</div>
			<br />
			<div id='actionButtons'>
				<button className='btn btn-outline-secondary' onClick={onPasswordChangeClick} id='changePasswordButton'>
					Change Password
				</button>
				{user?.role === ROLES.ADMIN.value && 
					<>
						&nbsp;&nbsp;&nbsp;
						<button className='btn btn-outline-primary' onClick={onEditClick} id={`editButton-${user?.id}`}>
							Edit
						</button>
					</>
				}
			</div>
		</div>
	);
};

export default UserInfoCard;