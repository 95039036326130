/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { receiptSchema } from "../../common/Validator";
import { createReceipt, getReceipt } from "../../api-wrapper/ReceiptApis";
import {PAYMENT_MODE,PAYMENT_MODE_LABELS } from "../../common/Variables";
function CreateReceipt() {
	const [pageError, setPageError] = useState('');
	const navigate = useNavigate();
	const location = useLocation();
	const [receipt, setReceipt] = useState(null);
	const [isCashPayment, setIsCashPayment] = useState(true);
	const [isBankTranser,setIsBankTransfer] = useState(false);
	const { CASH, CHEQUE, BANK_TRANSFER } = {
		CASH: 0,
		CHEQUE: 1,
		BANK_TRANSFER: 2
	};

	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	  };
	const [selectedDate, setSelectedDate] = useState(receipt?.dated ? new Date(receipt.dated) : formatDate(new Date()));	

	const methods = useForm({
		resolver: yupResolver(receiptSchema)
	});	

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		formState: { errors },
	} = methods;

	const onSubmit = async (data) => {
		try {
		  if (receipt) {
			data.id = receipt.id;
		  }	
		data.dated = selectedDate;
		data.paymentMode = Object.keys(PAYMENT_MODE).find(key => key == data.paymentMode);
		await createReceipt(data);
		navigate('/dashboard');
		} catch (error) {
			const errors = error.response?.data?.errors;
            if (errors) {
                for (const [key, value] of Object.entries(errors)) {
                    setError(key, { message: value });
                }
            } else if (error.response?.data) {
                setPageError(error.response.data);
            } else {
                setPageError('Something went wrong!Please try again.');
		  }
		}
	};

	const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedDate((value)); 
        };

	const handlePaymentModeChange = (e) =>{
		
		if (e.target.value == PAYMENT_MODE_LABELS.CASH){
			setIsCashPayment(true);
			setIsBankTransfer(false);
		}
		else if(e.target.value == PAYMENT_MODE_LABELS.BANK_TRANSFER){
			setIsCashPayment(false);
			setIsBankTransfer(true);
		}
		else
		{
			setIsCashPayment(false);
			setIsBankTransfer(false);

		}
	};

	useEffect (()=>{
		if(isCashPayment){
			setValue('drawnOn','');
			setValue('transactionNumber','');
		}
		if(isBankTranser){
			setValue('drawnOn','');
		}
	},[isCashPayment,isBankTranser]);

	useEffect(() => {
		if (location.pathname.includes('edit')) {
			const receiptId = location.pathname.split('edit/')[1];
			getReceipt(receiptId).then(response => {
				setReceipt(response);
				setValue('name', response.name);
				setValue('email', response.email);
				setValue('amount', response.amount);
				setValue('paymentMode', response.paymentMode.toString());
				setValue('transactionNumber', response.transactionNumber);
				setValue('panNumber', response.panNumber);
				setValue('drawnOn', response.drawnOn);
				setValue('address', response.address);
				setValue('dated',response.dated);
				setSelectedDate(response.dated);
				setValue('comment',response.comment);
				if(response.paymentMode === CASH ){
					setIsCashPayment(true);
					setIsBankTransfer(false);
				}
				else if(response.paymentMode === BANK_TRANSFER){
					setIsCashPayment(false);
					setIsBankTransfer(true);
				}
				else{
					setIsCashPayment(false);
					setIsBankTransfer(false);
				}
			})
			.catch(error=>{
				navigate('/dashboard', { state: { errorMessage: 'Receipt not found or has been deleted.' } });
			});
		}
		
	}, [location.pathname, setValue]);

	useEffect(() => {
        if (pageError !== '')           
			setTimeout(() => {
				setPageError('');
			}, 3500);
    }, [pageError]);

	

	return (
		<FormProvider {...methods}>
		<form className='create-user-form' onSubmit={handleSubmit(onSubmit)} id='createReceiptForm'>

			<div style={{height:"50px"}}>
				{(pageError !== '') && <div className='page-error' id='pageError'>{pageError}</div>}
			</div>
			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='name' id='nameLabel'>Name</label>
					<input
						{...register('name')}
						className='user-box-input'
						type='text'
						id='name'
						defaultValue={receipt?.name}
					/>
					{errors.name && <span className='error' id='nameError'>{errors.name.message}</span>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='email' id='emailLabel'>Email</label>
					<input
						{...register('email')}
						className='user-box-input'
						type='email'
						id='email'
						defaultValue={receipt?.email}
					/>
					{errors.email && <span className='error' id='emailError'>{errors.email.message}</span>}
				</div>
			</div>

			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='amount' id='amountLabel'>Amount</label>
					<input
						{...register('amount')}
						className='user-box-input'
						type='number'
						id='amount'
						defaultValue={receipt?.amount}
						required
						min={0}
						step = "0.01"
					/>
					{errors.amount && <span className='error' id='amountError'>{errors.amount.message}</span>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='paymentMode' id='paymentModeLabel'>Payment Mode</label>
					<select {...register('paymentMode')} id='paymentMode' className='user-box-input' defaultValue={receipt?.paymentMode} onChange={handlePaymentModeChange}>
					{Object.entries(PAYMENT_MODE).map(([key, value]) => (
								<option key={key} value={key}>{value}</option>
							))}
					</select>
					
					{errors.paymentMode && <span className='error' id='paymentModeError'>{errors.paymentMode.message}</span>}
				</div>
			</div>

			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='transactionNumber' id='transactionNumberLabel'>Transaction/Cheque Number</label>
					<input
						{...register('transactionNumber')}
						className='user-box-input'
						type='text'
						id='transactionNumber'
						defaultValue={receipt?.transactionNumber}
						disabled={isCashPayment}
					/>
					{errors.transactionNumber && <span className='error' id='transactionNumberError'>{errors.transactionNumber.message}</span>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='panNumber' id='panNumberLabel'>PAN Number</label>
					<input
						{...register('panNumber')}
						className='user-box-input'
						type='text'
						id='panNumber'
						maxLength='10'
						defaultValue={receipt?.panNumber}
					/>
					{errors.panNumber && <span className='error' id='panNumberError'>{errors.panNumber.message}</span>}
				</div>
			</div>

			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='drawnOn' id='drawnOnLabel'>Drawn On</label>
					<input
						{...register('drawnOn')}
						className='user-box-input'
						type='text'
						id='drawnOn'
						defaultValue={receipt?.drawnOn}
						disabled={isCashPayment || isBankTranser}
					/>
					{errors.drawnOn && <span className='error' id='drawnOnError'>{errors.drawnOn.message}</span>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='address' id='addressLabel'>Address</label>
					<textarea
						{...register('address')}
						className='user-box-input'
						id='address'
						defaultValue={receipt?.address}
						placeholder='Optional'
					/>
					{errors.address && <span className='error' id='addressError'>{errors.address.message}</span>}
				</div>
			</div>
			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='comment' id='commentLabel'>Comment</label>
					<textarea
						{...register('comment')}
						className='user-box-input'
						id='comment'
						defaultValue={receipt?.comment}
						placeholder='Optional'
						maxLength={150}
					/>
					{errors.comment && <span className='error' id='commentError'>{errors.comment.message}</span>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='dated' id='datedLabel'>Dated</label>
					<input 
						type="date"
						className='user-box-input'
						id="dated"
						name="ddated"
						value={selectedDate}
						onChange={handleChange}
						required
					/>
					{errors.dated && <span className='error' id='datedError'>{errors.dated.message}</span>}
				</div>
			</div>
			<br />

			<div>
				<button type='button' className='btn btn-secondary' onClick={() => navigate('/dashboard')} id='backButton'>
					Back
				</button>&nbsp;&nbsp;&nbsp;
				<button type='submit' className='btn btn-primary' id='submitButton'>
					Submit
				</button>
			</div>

		</form>
		</FormProvider>
	);
}

export default CreateReceipt;