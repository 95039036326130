/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useEffect } from "react";

function MainLayout({ nav }) {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname === '/') {
			navigate('/dashboard');
		}
	}, [location.pathname, navigate]);

	return (
		<div id='wrapper'>
			<Navbar nav={ nav } />
			<div className='outlet_wrapper' id='outletWrapper'>
				<Outlet />
			</div>
		</div>
	)
}

export default MainLayout;