/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword, validateResetPasswordToken } from "../../api-wrapper/PublicApis";
import { HttpStatusCode } from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "../Validator";

function ResetPassword() {
    const navigate = useNavigate();
    const paramKey = 'token';
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState('');
    const [isTokenValid, setTokenValid] = useState(false);
    const methods = useForm({
		resolver: yupResolver(resetPasswordSchema)
	});

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = methods;

    const onSubmit = (data) => {

        const response = resetPassword(paramKey, token, data);

        response.then(response => {
            navigate('/');
        }).catch(error => {
            setError('confirmNewPassword', { message: 'Invalid token, you can\'t reset password!' });
        });
	};

    useEffect(() => {
        setToken(searchParams.get(paramKey));

        const response = validateResetPasswordToken(paramKey, token);

        response.then(response => {
            if (response.status === HttpStatusCode.Ok) {
                setTokenValid(true);
            }
        }).catch(error => {
            if (error.response.status === HttpStatusCode.Unauthorized) {
                setTokenValid(false);
            }
        });

    }, [searchParams, token]);

    const BackButton = () => {
        return (
            <button type="submit" className="btn btn-secondary" onClick={e => navigate('/')} id='backButton'>
                Go To Homepage
            </button>
        );
    }

    return (
        <div className="mainContainer" id='resetPasswordPage'>
            {isTokenValid ?
                <FormProvider {...methods}>
			        <form className='change-password-form' onSubmit={handleSubmit(onSubmit)} id='resetPasswordForm'>
                        <div className='form-group'>
                            <input
                                {...register('newPassword')}
                                type='password'
                                className='user-box-input'
                                placeholder='Enter your new password here'
                                id='password'
                            />
                            {errors.newPassword && <span className='error' id='passwordError'>{errors.newPassword.message}</span>}
                        </div>
                        <div className='form-group'>
                            <input
                                {...register('confirmNewPassword')}
                                type='password'
                                className='user-box-input'
                                placeholder='Enter and confirm your new password here'
                                id='confirmPassword'
                            />
                            {errors.confirmNewPassword && <span className='error' id='confirmPasswordError'>{errors.confirmNewPassword.message}</span>}
                        </div>
                        <br />
                        <div>
                            <BackButton />&nbsp;&nbsp;
                            <button type='submit' className='btn btn-primary' id='submitButton'>
                                Submit
                            </button>
                        </div>
                    </form>
                </FormProvider>
            : 
                <div className="mainContainer" id='invalidTokenElement'>
                    <p id='invalidTokenMessage'>Invalid token, you can't reset password!</p>
                    <BackButton />
                </div>
            }
        </div>
    );
}

export default ResetPassword;