/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import ChangePasswordForm from "../../common/components/ChangePasswordForm";
import FinancialReport from "../../common/pages/FinancialReport";
import Profile from "../../common/pages/Profile";
import Admin from "../pages/Admin";
import CompanyProfile from "../pages/CompanyProfile";
import CreateCompanyProfile from "../pages/CreateCompanyProfile";
import CreateUser from "../pages/CreateUser";

const adminRoutes = [
    {
		path: '/dashboard',
		component: <Admin />
	},
	{
		path: '/dashboard/add-user',
		component: <CreateUser />
	},
	{
		path: '/dashboard/edit/*',
		component: <CreateUser />
	},
	{
		path: '/dashboard/change-password/*',
		component: <ChangePasswordForm />
	},
	{
		path: '/company-profile',
		component: <CompanyProfile />
	},
	{
		path: '/company-profile/save-profile',
		component: <CreateCompanyProfile />
	},
	{
		path: '/financial-report',
		component: <FinancialReport />
	},
	{
		path: '/profile',
		component: <Profile />
	},
	{
		path: '/profile/edit/*',
		component: <CreateUser />
	},
	{
		path: '/profile/change-password',
		component: <ChangePasswordForm />
	}
]

export default adminRoutes;