/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { securedApi } from '../service/AxiosInterceptor';

const base_api = `/admin/users`;

export const getOtherUsers = async () => {
	const response = await securedApi.get(base_api);
    return response.data;
};

export const createUser = async (data) => {
	const response = await securedApi.post(`${base_api}/save`, data);
    return response.data;
};

export const getUser = async (id) => {
	const response = await securedApi.get(`${base_api}/user/${id}`);
    return response.data;
};

export const deactivateUser = async (id) => {

	try {
		const response = await securedApi.put(`${base_api}/deactivate/${id}`);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error('Failed to deactivate user');
		}
	} catch (error) {
		console.error('Error deactivating user:', error);
	}
};

export const activateUser = async (id) => {

	try {
		const response = await securedApi.put(`${base_api}/activate/${id}`);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error('Failed to activate user');
		}
	} catch (error) {
		console.error('Error activating user:', error);
	}
};

export const resetPassword = async (id, data) => {
	const response = await securedApi.put(`${base_api}/change-password/${id}`, data);
    return response.data;
};

export const getAllUsers = async () => {
	const response = await securedApi.get(`${base_api}/get-all-users`);
	return response.data;
};