/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../api-wrapper/AuthenticatedApis";
import UserInfoCard from "../components/UserInfoCard";

function Profile() {
	const navigate = useNavigate();
	const [user, setUser] = useState({});

	useEffect(() => {
		getCurrentUser().then(response => setUser(response));
	}, []);

	const handlePasswordChangeClick = () => {
		navigate('/profile/change-password');
	};

	const handleEditClick = () => {
		navigate(`/profile/edit/${user.id}`);
	};

	return (
		<>
			<UserInfoCard
				user={user}
				onPasswordChangeClick={handlePasswordChangeClick}
				onEditClick={handleEditClick}
			/>
		</>
	)
}

export default Profile;