/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import Cookies from "js-cookie";
import { COOKIES_REFRESH_TOKEN, COOKIES_TOKEN } from "../common/Variables";
import { securedApi } from "../service/AxiosInterceptor";

const logoutApi = async () => {
	await securedApi.post('/logout');
    Cookies.remove(COOKIES_TOKEN);
    Cookies.remove(COOKIES_REFRESH_TOKEN);
}

export const logout = async (param = '?logout') => {
	await logoutApi();
	window.location.href = `/${param}`;
};

export const getCurrentUser = async () => {
	const response = await securedApi.get('/get-current-user');
	return response.data;
};

export const changePassword = async (data) => {
	await securedApi.put('/change-password', data);
	logout('?passwordChanged');
};