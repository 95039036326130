/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { Link, useLocation } from "react-router-dom";
import { logout } from "../../api-wrapper/AuthenticatedApis";
import { getCurrentUser } from "../../api-wrapper/AuthenticatedApis";
import { useEffect, useState } from "react";
import Dialog from "./Dialog";
import { ROLES } from "../../common/Variables";

function Navbar({ nav }) {
	let location = useLocation();
	const [user, setUser] = useState({});
	const [show, setShow] = useState(false);
	
	const handleOk = () => {
		setShow(false);
		logout();
	}

	useEffect(() => {		
		getCurrentUser().then(response=> setUser(response));
	}, []);

	return (
		<nav className='navbar navbar-expand-sm' style={{borderTop: 'groove', borderBottom: 'groove', textShadow: '0 0 black', fontSize: 'large'}} id='navigationBar'>
			<Dialog show={show} message={'Do you want to logout?'} onCancel={() => setShow(false)} onOk={handleOk} />
			<div className='container-fluid' style={{justifyContent: 'flex-start'}}>
				<span className="navbar-brand" style={{cursor: 'default'}} id='appName'>
					Donation System
				</span>
				<ul className='navbar-nav' id='navigation'>
					{
						nav?.map((element, i) => {
							return (
								<li key={i} className='nav-item'>
									<Link className={`nav-link ${location.pathname.startsWith(element.path) ? 'active-link' : ''}`} 
										to={element.path}>{element.name}</Link>
								</li>
							)
						})
					}
					<li className='nav-item'>
						<a className={`nav-link`} onClick = {() => setShow(true)} id='logoutButton'>Logout</a>
					</li>
				</ul>
			</div>
			<div className='container-fluid ' style={{justifyContent: 'flex-end'}}>
				<div className="d-flex flex-column" >
					<span>
						{user.firstName+" "+user.lastName}
					</span>
					{(user.role!=undefined) && 
						<span style={{fontSize: 'small', color: 'gray' , marginLeft : 'auto'}}>
						{(Object.values(ROLES).find(role => role.value === user.role)).name}
						</span>
					}
				</div>
			</div>
		</nav>
	)
}

export default Navbar;