/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useNavigate } from "react-router-dom";

function ErrorPage() {
	const navigate = useNavigate();
	
	return (
		<div id="error-page" className="mainContainer">
			<h1>Oops!</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<br />
			<div>
				<button type="button" className="btn btn-secondary" onClick={() => navigate(-1)} id='backButton'>Back</button> &nbsp;&nbsp;&nbsp;
				<button type="button" className="btn btn-primary" onClick={() => navigate('/')} id='homeButton'>Home</button>
			</div>
		</div>
	);
}

export default ErrorPage;