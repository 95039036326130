/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState } from "react";
import Table from "../../common/components/Table";
import { deleteReceipt, getAllReceiptsByUser, downloadReceipt, printReceipt, emailReceipt } from "../../api-wrapper/ReceiptApis";
import {exportXls} from '../../api-wrapper/ExportXlsApi';
import { useNavigate,useLocation } from "react-router";
import { RECEIPT_STATUS, RECEIPT_STATUS_COLOR, PAYMENT_MODE } from "../../common/Variables";
import { BarLoader } from "react-spinners";
import Dialog from "../../common/components/Dialog";

function User() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [receipts, setReceipts] = useState([]);
	const [showConfirm, setShowConfirm] = useState(false);
	const [rowToUpdate, setRowToUpdate] = useState(null);
	const location = useLocation();
	const [pageError,setPageError]=useState('');
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	useEffect(() => {
		if (location.state?.errorMessage) {
			setPageError(location.state.errorMessage);
			navigate('/dashboard', { replace: true, state: {} });
		  }
		getAllReceiptsByUser().then(response => setReceipts(response));
	}, [location.state, navigate]);

	useEffect(() => {
        if (pageError !== '')
			setTimeout(() => {
				setPageError('');
			}, 3500);
    }, [pageError]);

	const getStausNameString = (status) =>{
		for(const [key,value] of Object.entries(RECEIPT_STATUS)){
			if( value === status) {
				return key;
			}
		}
	};
 
	const columns = [
		{
			name: 'Receipt Id',
			selector: row => row.receiptId,
			sortable: true,
			wrap: true,
			id: 'receiptId'
		},
		{
			name: 'Created On',
			selector: row => {
				let createdDate = new Date(row.createdOn);
					let day = createdDate.getDate();
					let month = createdDate.getMonth() + 1;
					let year = createdDate.getFullYear().toString();
					return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
			},
			sortable: true,
			wrap: true,
			id: 'createdOn'
		},
		{
			name: 'Dated',
			selector: row => {
				let createdDate = new Date(row.dated);
					let day = createdDate.getDate();
					let month = createdDate.getMonth() + 1;
					let year = createdDate.getFullYear().toString();
					return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
			},
			sortable: true,
			wrap: true,
			id: 'dated'
		},
		{
			name: 'Created By',
			selector: row => row.createdBy.firstName + ' ' + row.createdBy.lastName,
			wrap: true,
			id: 'createdBy'
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
			wrap: true,
			id: 'name'
		},
		{
			name: 'Email',
			selector: row => row.email,
			wrap: true,
			grow: 1.5,
			id: 'email'
		},
		{
			name: 'Address',
			selector: row => row.address,
			wrap: true,
			grow: 2,
			id: 'address'
		},
		{
			name: 'Amount',
			selector: row => row.amount,
			sortable: true,
			wrap: true,
			id: 'amount'
		},
		{
			name: 'Payment Mode',
			selector: row => PAYMENT_MODE[row.paymentMode],
			wrap: true,
			id: 'paymentMode'
		},
		{
			name: 'Transaction/Cheque Number',
			selector: row => row.transactionNumber,
			wrap: true,
			id: 'transactionNumber'
		},
		{
			name: 'PAN Number',
			selector: row => row.panNumber,
			wrap: true,
			id: 'panNumber'
		},
		{
			name: 'Drawn On',
			selector: row => row.drawnOn,
			wrap: true,
			id: 'drawnOn'
		},
		{
			name: 'Comment',
			selector: row => row.comment,
			wrap: true,
			id: 'comment',
			width: '220px',
			cell: (row) => (
				<div className='reason-container' style={{ display: 'flex', alignItems: 'center' }}>
				  <label className='reason-label' style={{ border: 'none', padding : 0 }}>{row.comment}</label>
				</div>
			  )
		},
		{
			name: 'Status',
			grow: 1,
			cell: row => {
				const statusColor = {
					APPROVED: RECEIPT_STATUS_COLOR.Approved,
					DECLINED: RECEIPT_STATUS_COLOR.Declined,
					PENDING: RECEIPT_STATUS_COLOR.Pending,
				}[getStausNameString(row.receiptStatus)];
		
				return (
					<div
						style={{
							backgroundColor: statusColor,
							padding: "10px",
							borderRadius: "10px",
						}}
						id='receiptStatus'
					>
					{getStausNameString(row.receiptStatus)}
					</div>
				);
			},
			id: 'status'
		},
		{
			name: 'Action',
			cell: (row) => (
				<div className='button-container' style={{'display': 'flex', 'gap': '10px', alignItems: 'center'}} id='actionButtons'>
					{row.receiptStatus !== RECEIPT_STATUS.APPROVED && (
						<div className='button-container' style={{'display': 'flex', 'gap': '10px', alignItems: 'center'}} id='actionButtons'>
							{row.receiptStatus !== RECEIPT_STATUS.DECLINED && (
								<button
									className='btn btn-outline-info'
									onClick={e => navigate(`/dashboard/edit/${row.id}`)}
									id={`editButton-${row.id}`}
								>
									Edit
								</button>
							)}
							<button
								className='btn btn-outline-danger'
								onClick={async (e) => confirm(row)}
								id={`deleteButton-${row.id}`}
							>
								Delete
							</button>
						</div>
					)}					
					{row.receiptStatus === RECEIPT_STATUS.DECLINED && (
						row.reasonToDecline &&
						<>
							<div className='reason-container' style={{ display: 'flex', alignItems: 'center' }}>
                    			<label className='reason-label'>Reason: {row.reasonToDecline}</label>
                			</div>
						</>
						)}
					{row.receiptStatus === RECEIPT_STATUS.APPROVED && (
						<>
						<div className='button-container' style={{'display': 'flex', 'gap': '10px'}}>
							<button
								className='btn btn-outline-primary'
								onClick={async (e) => {
									setLoading(true);
									try {
										const response = await downloadReceipt(row.id);
										const url = window.URL.createObjectURL(new Blob([response]));
										const link = document.createElement('a');
										link.href = url;
										link.setAttribute('download', 'Reciept.pdf');
										document.body.appendChild(link);
										link.click();
									} catch (error) {
										if (error.response.status === 400) {
											window.alert('Make sure..\n1. Receipt Id is valid\n2. Receipt is approved\n3. Company profile is created');
										}
										console.error('Error downloading PDF:', error);
									} finally {
										setLoading(false);
									}
								}}
							>
								Download
							</button>
							<button
								className='btn btn-outline-secondary'
								onClick={async (e) => {
									setLoading(true);
									try {
										const response = await printReceipt(row.id);
										const file = new Blob([response], { type: 'application/pdf' });
										const fileURL = URL.createObjectURL(file);
										const iframe = document.createElement('iframe');
										iframe.style.display = 'none';
										iframe.src = fileURL;
										document.body.appendChild(iframe);
										iframe.contentWindow?.print(); // Print the PDF
									} catch (error) {
										if (error.response.status === 400) {
											window.alert('Make sure..\n1. Receipt Id is valid\n2. Receipt is approved\n3. Company profile is created');
										}
										console.error('Error printing PDF:', error);
									} finally {
										setLoading(false);
									}
								}}
							>
								Print
							</button>
							{row.email && 
								<button
								className = {`btn ${row.emailed ? 'btn-outline-success' : 'btn-outline-secondary'}`}
								onClick = {async (e) => {
									setLoading(true);
									try {
										const response = await emailReceipt(row.id);
										console.log("Response:",response);
										if (response) {

											alert('Email sent successfully');
											const updatedReceipt = { ...response}; 
											setReceipts(receipts.map(receipt => receipt.id === row.id ? updatedReceipt : receipt));
										}
										else{
											alert("Email could not be sent. Try again later.")
										}
									} catch (error) {
										if (error.response.status === 400) {
											alert('Make sure..\n1. Receipt Id is valid\n2. Receipt is approved\n3. Company profile is created');
										}else
											alert("Email could not be sent. Try again later.");
										console.error('Error emailing receipt:', error);
									} finally {
										setLoading(false);
									}
								}}
								id={`emailButton-${row.id}`}
							>
								{row.emailed ? 'Emailed' : 'Email'}
								</button>
							}
							</div>
						</>
					)}
				</div>
			),
			grow: 1.5,
			id: 'action'
		}
	]

	const confirm = (row) => {
		setRowToUpdate(row);
		setShowConfirm(true);
	}

	const handleCancel = () => {
		setShowConfirm(false);
		setRowToUpdate(null);
	}

	const alert = (message) => {
		setAlertMessage(message);
		setShowAlert(true);
	};

	const handleConfirm = async (response) => {
		handleCancel();

		const row = response.data;

		setLoading(true);
		try {
			const response = await deleteReceipt(row.id);

			if (response) {
				setReceipts(receipts.filter((receipt) => receipt.id !== row.id));
			}
		} catch (error) {
			setPageError('Error processing your request. Please try again.');
			console.error('Error deleting receipt:', error);
		} finally {
			setLoading(false);
		}
	}

	const handleExport = async() =>{
		try {
			const response = await exportXls();
			if(response.status == 200)
			{
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Report.xlsx');
				document.body.appendChild(link);
				link.click();
			}
			if(response.status == 204)
			{
				setPageError("No records available.");
			}
		} catch (error) {
			setPageError("Error exporting records. Try again later.");
			console.log(error);
		}
	}

	return (
		<div className='container-fluid' id='userDashboard'>
			<Dialog 
				show={showAlert} 
				message={alertMessage} 
				confirmType={false}
				onOk={() => {
					setShowAlert(false);
					setAlertMessage('');
				}} />
			<Dialog 
				show={showConfirm} 
				message={
                    <span>
                        Do you really want to delete this receipt with id{' '}
                        <strong>{rowToUpdate?.receiptId}</strong>?
                    </span>
                }  
				onCancel={handleCancel}
				onOk={(response) => handleConfirm(response)}
				data={rowToUpdate} />
			<BarLoader loading={loading} width={'100%'} id='loader' />
			<div style={{height:"30px" ,justifyContent : 'flex-start'}}>
				{(pageError !== '') && <div className='page-error' id='pageError'>{pageError}</div>}
			</div>
			<button
				className='btn btn-primary'
				style={{margin:15}}
				onClick={e => navigate('/dashboard/add-receipt')}
				id='addReceiptButton'
			>
				Add Receipt
			</button>
			<Table
				columns={columns}
				data={receipts}
				conditionalStyle={false} />
			<button
				className='btn btn-primary'
				style={{margin:15}}
				onClick={handleExport}
				id='xlsExportButton'
				>
				Export			
			</button>
		</div>
		
	)
}

export default User;