/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { securedApi } from "../service/AxiosInterceptor";

const base_api = `/admin/company-profile`;

export const getCompanyProfile = async () => {
	const response = await securedApi.get(`${base_api}`);
    return response.data;
};

export const saveCompanyProfile = async (profile) => {

    const formData = new FormData();

    // Append profile data to FormData
    Object.keys(profile).forEach((key) => {
        if (key === 'logo') {
            formData.append(key, new Blob([profile[key]], { type: 'image/png' }));
        } else {
            formData.append(key, profile[key]);
        }
    });

    const response = await securedApi.post(`${base_api}/save`, formData, {headers: {'Content-Type': 'multipart/form-data',},});
    return response.data;
};
