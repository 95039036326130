/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState } from "react";
import { activateUser, deactivateUser, getOtherUsers } from "../../api-wrapper/UserApis";
import Table from "../../common/components/Table";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import Dialog from "../../common/components/Dialog";
import { ROLES,GENDER} from "../../common/Variables";

function Admin() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	const [showConfirm, setShowConfirm] = useState(false);
	const [confirmMessage, setConfirmMessage] = useState('');
	const [rowToUpdate, setRowToUpdate] = useState(null);
	const [action, setAction] = useState(null);

	const ACTION_TYPE = {
		Activate: 'ACTIVATE',
		Deactivate: 'DEACTIVATE'
	}

	useEffect(() => {
		getOtherUsers().then(response => setUsers(response));
	}, []);
 
	const columns = [
		{
			name: 'First Name',
			selector: row => row.firstName,
			sortable: true,
			grow: 1,
			id: 'firstName'
		},
		{
			name: 'Last Name',
			selector: row => row.lastName,
			sortable: true,
			grow: 1,
			id: 'lastName'
		},
		{
			name: 'Email',
			selector: row => row.email,
			grow: 1.5,
			id: 'email'
		},
		{
			name: 'Gender',
			selector: row => GENDER[row.gender],
			grow: 0.5,
			id: 'gender'
		},
		{
			name: 'Role',
			selector: row =>(Object.values(ROLES).find(role => role.value ===  row.role)).name ,
			grow: 0.5,
			id: 'role'
		},
		{
			name: 'Action',
			cell: (row) => (
				<div className='button-container' style={{'display': 'flex', 'gap': '10px'}} id='actionButtons'>
					<button
						className='btn btn-outline-info'
						onClick={e => navigate(`/dashboard/edit/${row.id}`)}
						id={`editButton-${row.id}`}
					>
						Edit
					</button>
					<button
						className='btn btn-outline-secondary'
						onClick={e => navigate(`/dashboard/change-password/${row.id}`)}
						id={`changePasswordButton-${row.id}`}
					>
						Change Password
					</button>
					{row.enabled ? 
						<button
							className='btn btn-outline-danger'
							onClick={async (e) => confirm(row, ACTION_TYPE.Deactivate)}
							id={`deactivateButton-${row.id}`}
						>
							Deactivate
						</button>
					:
						<button
							className='btn btn-outline-success'
							onClick={async (e) => confirm(row, ACTION_TYPE.Activate)}
							id={`activateButton-${row.id}`}
						>
							Activate
						</button>
					}
				</div>
			),
			grow: 2,
			id: 'action'
		}
	]

	const confirm = (row, action) => {
		setAction(action);
		setRowToUpdate(row);
		setConfirmMessage(`Do you really want to ${action} this user : ${row.firstName} ${row.lastName}?`);
		setShowConfirm(true);
	}

	const handleCancel = () => {
		setShowConfirm(false);
		setAction(null);
		setRowToUpdate(null);
		setConfirmMessage('');
	}

	const handleConfirm = (response) => {
		handleCancel();
		
		const action = response.action;
		const row = response.data;

		switch (action) {
			case ACTION_TYPE.Activate:
				return activate(row);
			
			case ACTION_TYPE.Deactivate:
				return deactivate(row);
		
			default:
				return;
		}
	}

	const activate = async (row) => {
		setLoading(true);
		try {
			const response = await activateUser(row.id);

			if (response != null) {
				setUsers(users.map(u => u.id === response.id ? response : u));
			}
		} catch (error) {
			console.error('Error activating user:', error);
		} finally {
			setLoading(false);
		}
	}

	const deactivate = async (row) => {
		setLoading(true);
		try {
			const response = await deactivateUser(row.id);

			if (response != null) {
				setUsers(users.map(u => u.id === response.id ? response : u));
			}
		} catch (error) {
			console.error('Error deactivating user:', error);
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className='container-fluid' id='adminDashboard'>
			<Dialog 
				show={showConfirm} 
				message={confirmMessage} 
				onCancel={handleCancel}
				onOk={(response) => handleConfirm(response)}
				action={action}
				data={rowToUpdate} />
			<BarLoader loading={loading} width={'100%'} id='loader' />
			<button
				className='btn btn-primary'
				style={{margin:15}}
				onClick={e => navigate('/dashboard/add-user')}
				id='addUserButton'
			>
				Add User
			</button>
			<Table
				columns={columns}
				data={users}
				conditionalStyle={true} />
		</div>
	)
}

export default Admin;