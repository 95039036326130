/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import axios from "axios";
import { BASE_URL, COOKIES_REFRESH_TOKEN, COOKIES_TOKEN } from "../common/Variables";
import Cookies from "js-cookie";
import { refreshToken } from "../api-wrapper/PublicApis";
import { logout } from "../api-wrapper/AuthenticatedApis";

export const securedApi = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	}
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshTokenResponse = (token) => {
	refreshSubscribers.map((cb) => cb(token));
	refreshSubscribers = [];
};

securedApi.interceptors.request.use(
	(config) => {
		const token = Cookies.get(COOKIES_TOKEN);
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
			return config;
		}
		window.location.href = '/';
		return Promise.reject(new Error('Access token is missing'));
	},
	(error) => Promise.reject(error)
);

securedApi.interceptors.response.use(
	(response) => response,
	async (error) => {
	const originalRequest = error.config;

		if (error.response.status === 403)
			await logout('?userDisabled');
		if (error.response && error.response.status === 401 && !originalRequest._retry) {
			if (!isRefreshing) {
				isRefreshing = true;
		
				const token = Cookies.get(COOKIES_REFRESH_TOKEN);
		
				if (!token) {
					redirectToLogin();
					return Promise.reject(error);
				}
		
				try {
					const refreshResponse = await refreshToken();

					const newAccessToken = refreshResponse.data.token;
					Cookies.set(COOKIES_TOKEN, newAccessToken);
							
					originalRequest._retry = true;
					originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
		
					refreshSubscribers.push((token) => {
						originalRequest.headers.Authorization = `Bearer ${token}`;
					});
		
					return securedApi(originalRequest);

				} catch (refreshError) {
					redirectToLogin();
					return Promise.reject(error);
				} finally {
					isRefreshing = false;
					while (refreshSubscribers.length > 0) {
						refreshSubscribers.shift()();
					}
				}
			} else {
				return new Promise((resolve) => {
					refreshSubscribers.push((token) => {
						originalRequest.headers.Authorization = `Bearer ${token}`;
						resolve(securedApi(originalRequest));
					});
				});
			}
		}
 
		return Promise.reject(error);
	}
);

export const refreshApi = axios.create({
	baseURL: BASE_URL
});

refreshApi.interceptors.request.use(
	(config) => {
		const token = Cookies.get(COOKIES_REFRESH_TOKEN);
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

export const redirectToLogin = (param = null) => {
	Cookies.remove(COOKIES_TOKEN);
	Cookies.remove(COOKIES_REFRESH_TOKEN);
	if (param)
		window.location.href =  `/${param}`
	else
		window.location.href = '/';
};