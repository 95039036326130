/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import ChangePasswordForm from "../../common/components/ChangePasswordForm";
import FinancialReport from "../../common/pages/FinancialReport";
import Profile from "../../common/pages/Profile";
import Reviewer from "../pages/Reviewer";
import CreateReceipt from "../../common/pages/CreateReceipt";

const reviewerRoutes = [
    {
		path: '/dashboard',
		component: <Reviewer />
	},
	{
		path: '/financial-report',
		component: <FinancialReport />
	},
    {
		path: '/profile',
		component: <Profile />
	},
	{
		path: '/profile/change-password',
		component: <ChangePasswordForm />
	},
	{
		path: '/dashboard/edit/*',
		component: <CreateReceipt />
	},
]

export default reviewerRoutes;