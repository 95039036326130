/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { forgotPasswordRequest } from "../../api-wrapper/PublicApis";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordSchema } from "../Validator";
import { useNavigate } from "react-router-dom";
import { redirectToLogin } from "../../service/AxiosInterceptor";
import { Alert } from "bootstrap";

function ForgotPassword() {
	const navigate = useNavigate();
	const methods = useForm({
		resolver: yupResolver(forgotPasswordSchema),
	});

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = methods;

	const onSubmit = (email) => {
		const response = forgotPasswordRequest(email);

		response.then((result) => {
			redirectToLogin('?recoveryMailSent');
		}).catch((error) => {
			let message = 'Something went wrong!';

			if (error.response.status === 400) {
				message = error.response.data;
			} else if (error.response.status === 425) {
				message = 'Limit reached, please try again after ' + error.response.data + ' minutes!';
			}

			setError('email', { message: message });
		});
	};

	return (
		<FormProvider {...methods}>
			<form className='mainContainer' onSubmit={handleSubmit(onSubmit)} id='forgotPasswordForm'>
				<div className='form-group'>
					<input
						{...register('email')}
						className='user-box-input'
						type='email'
						placeholder='Enter your email here'
						id='email'
					/>
					{errors.email && <span className='error' id='emailError'>{errors.email.message}</span>}
				</div>
				<br />
				<div>
				<button type='button' className='btn btn-secondary' onClick={e => navigate('/')} id='backButton'>
					&nbsp;Back&nbsp;
				</button>&nbsp;&nbsp;
				<button type='submit' className='btn btn-primary' id='submitButton'>
					&nbsp;Submit&nbsp;
				</button>
				</div>
			</form>
		</FormProvider>
	);
}

export default ForgotPassword;