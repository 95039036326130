/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { createUserSchema } from '../Validator';
import { createUser } from '../../api-wrapper/UserApis';
import { logout } from '../../api-wrapper/AuthenticatedApis';

function RegistrationModal({ show, handleClose }) {
    const methods = useForm({
		resolver: yupResolver(createUserSchema)
	});

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = methods;

    const onSubmit = async (data) => {
		try {
			await createUser(data);
            logout('?adminCreated');
		} catch (error) {
			const errors = error.response?.data?.errors;
			if (errors) {
				for (const [key, value] of Object.entries(errors)) {
					setError(key, { message: value });
				}
			} else if (error.response?.data?.includes('Duplicate entry \''+data.email+'\'')) {
				setError('email', { message: 'Email already exist!' });
			} else {
				setError('email', { message: 'Something went wrong!' });
			}
		}
	};

    return (
        <Modal show={show} fullscreen id='registrationModal'>
            <Modal.Header style={{alignSelf: 'center'}} id='registrationModalHeader'>
                <Modal.Title id='registrationModalTitle'>Create Admin</Modal.Title>
            </Modal.Header>
            <Modal.Body id='registrationModalBody'>
                <FormProvider {...methods}>
                    <form className='create-user-form' onSubmit={handleSubmit(onSubmit)} id='registrationForm'>

                        <div className='form-row'>
                            <div className='form-field-container'>
                                <label htmlFor='firstName' id='firstNameLabel'>First Name</label>
                                <input
                                    {...register('firstName')}
                                    className='user-box-input'
                                    type='text'
                                    id='firstName'
                                />
                                {errors.firstName && <span className='error' id='firstNameError'>{errors.firstName.message}</span>}
                            </div>
                            <div className='form-field-container'>
                                <label htmlFor='lastName' id='lastNameLabel'>Last Name</label>
                                <input
                                    {...register('lastName')}
                                    className='user-box-input'
                                    type='text'
                                    id='lastName'
                                />
                                {errors.lastName && <span className='error' id='lastNameError'>{errors.lastName.message}</span>}
                            </div>
                        </div>

                        <div className='form-row'>
                            <div className='form-field-container'>
                                <label htmlFor='email' id='emailLabel'>Email</label>
                                <input
                                    {...register('email')}
                                    className='user-box-input'
                                    type='email'
                                    id='email'
                                />
                                {errors.email && <span className='error' id='emailError'>{errors.email.message}</span>}
                            </div>
                            <div className='form-field-container'>
                                <label htmlFor='gender' id='genderLabel'>Gender</label>
                                <select {...register('gender')} id='gender' className='user-box-input'>
                                    <option value='MALE'>Male</option>
                                    <option value='FEMALE'>Female</option>
                                    <option value='OTHER'>Other</option>
                                </select>
                                {errors.gender && <span className='error' id='genderError'>{errors.gender.message}</span>}
                            </div>
                        </div>

                        <div className='form-row'>
                            <div className='form-field-container'>
                                <label htmlFor='password' id='passwordLabel'>Password</label>
                                <input
                                    {...register('password')}
                                    className='user-box-input'
                                    type='password'
                                    id='password'
                                />
                                {errors.password && <span className='error' id='passwordError'>{errors.password.message}</span>}
                            </div>
                            <div className='form-field-container'>
                                <label htmlFor='confirmPassword' id='confirmPasswordLabel'>Confirm Password</label>
                                <input
                                    {...register('confirmPassword')}
                                    className='user-box-input'
                                    type='password'
                                    id='confirmPassword'
                                />
                                {errors.confirmPassword && <span className='error' id='confirmPasswordError'>{errors.confirmPassword.message}</span>}
                            </div>
                        </div>

                        <div className='form-row'>
                            <div className='form-field-container'>
                                <label htmlFor='role' id='roleLabel'>Role</label>
                                <select {...register('role')} id='role' className='user-box-input'>
                                    <option value='ADMIN'>ADMIN</option>
                                </select>
                                {errors.role && <span className='error' id='roleError'>{errors.role.message}</span>}
                            </div>
                        </div>

                        <br />

                        <div>
                            <button type='button' className='btn btn-secondary' onClick={() => logout()} id='logoutButton'>
                                Logout
                            </button>&nbsp;&nbsp;&nbsp;
                            <button type='submit' className='btn btn-primary' id='submitButton'>
                                Submit
                            </button>
                        </div>

                    </form>
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
}

export default RegistrationModal;