/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const COOKIES_TOKEN = 'jwt-token';
export const COOKIES_REFRESH_TOKEN = 'refresh-jwt-token';

export const DEFAULT_USER = 'default-user@gohiltech.com';

export const ROLES ={
	ADMIN: { name: 'ADMIN', value: 0 },
    USER: { name: 'USER', value: 1 },
    REVIEWER: { name: 'REVIEWER', value: 2 }

}

export const RECEIPT_STATUS = {
	PENDING  : 0,
	APPROVED : 1,
	DECLINED : 2
}

export const RECEIPT_STATUS_COLOR = {
	Pending: 'lightblue',
	Approved: 'lightgreen',
	Declined: '#FF474C'
}

export const LOGIN_PAGE_SEARCH_PARAM = {
	Logout: 'logout',
	PasswordChanged: 'passwordChanged',
	AdminCreated: 'adminCreated',
	UserDisabled: 'userDisabled',
	RecoveryEmailSent : 'recoveryMailSent'
}

export const GENDER = {
    0: 'Male',
    1: 'Female',
    2: 'Other'
};

export const PAYMENT_MODE = {
    0 : 'CASH',
    1 : 'CHEQUE',
    2 : 'BANK_TRANSFER'
}

export const PAYMENT_MODE_LABELS = {
	CASH : 0,
	CHEQUE : 1,
	BANK_TRANSFER : 2

}