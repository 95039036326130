/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import * as Yup from 'yup';

const email =
	/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i;

const emailSchema = Yup.string()
	.required('Email is required')
	.matches(email, 'Please check your email');

const passwordSchema = Yup.string()
	.required('Password is required')
	.min(8, 'Password must be at least 8 characters')
	.max(15, 'Password must not exceed 15 characters');

export const loginSchema = Yup.object().shape({
	email: emailSchema,
	password: passwordSchema
});

const firstNameSchema = Yup.string().required('Name is required');

const lastNameSchema = Yup.string().required('Last name is required');

export const createUserSchema = Yup.object().shape({
	firstName: firstNameSchema,
	lastName: lastNameSchema,
	email: emailSchema,
	password: passwordSchema,
	confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const updateUserSchema = Yup.object().shape({
	firstName: firstNameSchema,
	lastName: lastNameSchema,
	email: emailSchema
});

const confirmNewPasswordSchema = Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match');

export const changePasswordSchema = Yup.object().shape({
	currentPassword: passwordSchema,
	newPassword: passwordSchema,
	confirmNewPassword: confirmNewPasswordSchema
});

export const resetPasswordSchema = Yup.object().shape({
	newPassword: passwordSchema,
	confirmNewPassword: confirmNewPasswordSchema
});

const panNumberSchema = Yup.string()
	.required('PAN is required')
	.matches('[A-Z]{5}[0-9]{4}[A-Z]{1}', 'Please check your PAN');

export const receiptSchema = Yup.object().shape({
	name: firstNameSchema,
	panNumber: panNumberSchema
});

export const companyProfileSchema = Yup.object().shape({
	organizationName: Yup.string().required('Organization name is required'),
	regNumber: Yup.string().required('Reg number is required'),
	panNumber: panNumberSchema,
	address: Yup.string().required('Address is required'),
	email: emailSchema,
	signatureRequired: Yup.boolean().required('signatureRequired  is required'),
    logo: Yup.mixed().required().test("fileRequired", "Logo is required", function(value) {
		const originalLogo = this.parent ? this.parent.logo : null;
		if (value && originalLogo) {
			value = originalLogo;
			return true;
		}
		return value && value.length === 1;
    })
});

export const forgotPasswordSchema = Yup.object().shape({
	email: emailSchema
});