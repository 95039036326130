/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { createUserSchema, updateUserSchema } from "../../common/Validator";
import { createUser, getUser } from "../../api-wrapper/UserApis";
import { ROLES,GENDER} from "../../common/Variables";

function CreateUser() {
	const [pageError, setPageError] = useState('');
	const navigate = useNavigate();
	const location = useLocation();
	const [user, setUser] = useState(null);
	const methods = useForm({
		resolver: yupResolver(user == null ? createUserSchema : updateUserSchema)
	});

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		formState: { errors },
	} = methods;

	const onSubmit = async (data) => {
		try {
			if (user) {
				data.id = user.id;
				data.password = user.password;
			}
			await createUser(data);
    		navigate(-1);
		} catch (error) {
			const errors = error.response?.data?.errors;
			if (errors) {
				for (const [key, value] of Object.entries(errors)) {
					setError(key, { message: value });
				}
			} else if (error.response?.data?.includes('Duplicate entry \''+data.email+'\'')) {
				setError('email', { message: 'Email already exist!' });
			} else if (error.response?.data) {
				setPageError(error.response.data);
			} else {
				setPageError('Something went wrong!');
			}
		}
	};

	useEffect(() => {
		if (location.pathname.includes('edit')) {
			const userId = location.pathname.split('edit/')[1];
			getUser(userId).then(response => {
				setUser(response);
				setValue('firstName', response.firstName);
				setValue('lastName', response.lastName);
				setValue('email', response.email);
				setValue('gender', response.gender);
				setValue('role', response.role);
			});
		}
	}, [location.pathname, setValue]);

	useEffect(() => {
        if (pageError !== '')           
			setTimeout(() => {
				setPageError('');
			}, 3500);
    }, [pageError]);

	return (
		<FormProvider {...methods}>
		<form className='create-user-form' onSubmit={handleSubmit(onSubmit)} id='createUserForm'>
			
			<div style={{height:"50px"}}>
				{(pageError !== '') && <div className='page-error' id='pageError'>{pageError}</div>}
			</div>
			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='firstName' id='firstNameLabel'>First Name</label>
					<input
						{...register('firstName')}
						className='user-box-input'
						type='text'
						id='firstName'
						defaultValue={user?.firstName}
					/>
					{errors.firstName && <span className='error' id='firstNameError'>{errors.firstName.message}</span>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='lastName' id='lastNameLabel'>Last Name</label>
					<input
						{...register('lastName')}
						className='user-box-input'
						type='text'
						id='lastName'
						defaultValue={user?.lastName}
					/>
					{errors.lastName && <span className='error' id='lastNameError'>{errors.lastName.message}</span>}
				</div>
			</div>

			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='email' id='emailLabel'>Email</label>
					<input
						{...register('email')}
						className='user-box-input'
						type='email'
						id='email'
						defaultValue={user?.email}
					/>
					{errors.email && <span className='error' id='emailError'>{errors.email.message}</span>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='gender' id='genderLabel'>Gender</label>
					<select {...register('gender')} id='gender' className='user-box-input' defaultValue={user?.gender}>
					{Object.entries(GENDER).map(([key, value]) => (
                            <option key={key} value={key}>{GENDER[key]}</option>
                        ))}
					</select>
					{errors.gender && <span className='error' id='genderError'>{errors.gender.message}</span>}
				</div>
			</div>

			{
				user === null ? 
				<div className='form-row'>
					<div className='form-field-container'>
						{user !== null 
							? <label htmlFor='password' style={{color: 'gray'}} id='passwordLabel'>Password</label> 
							: <label htmlFor='password' id='passwordLabel'>Password</label>
						}
						<input
							{...register('password')}
							className='user-box-input'
							type='password'
							id='password'
						/>
						{errors.password && <span className='error' id='passwordError'>{errors.password.message}</span>}
					</div>
					<div className='form-field-container'>
						{user !== null 
							? <label htmlFor='confirmPassword' style={{color: 'gray'}} id='confirmPasswordLabel'>Confirm Password</label> 
							: <label htmlFor='confirmPassword' id='confirmPasswordLabel'>Confirm Password</label>
						}
						<input
							{...register('confirmPassword')}
							className='user-box-input'
							type='password'
							id='confirmPassword'
						/>
						{errors.confirmPassword && <span className='error' id='confirmPasswordError'>{errors.confirmPassword.message}</span>}
					</div>
				</div> : <></>
			}

			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='role' id='roleLabel'>Role</label>
					<select {...register('role')} id='role' className='user-box-input' defaultValue={user?.role}>
						{Object.entries(ROLES).map(([key, role]) => (
								<option key={role.value} value={role.value}>{role.name}</option>
							))}
					</select>
					{errors.role && <span className='error' id='roleError'>{errors.role.message}</span>}
				</div>
			</div>

			<br />

			<div>
				<button type='button' className='btn btn-secondary' onClick={() => navigate(-1)} id='backButton'>
					Back
				</button>&nbsp;&nbsp;&nbsp;
				<button type='submit' className='btn btn-primary' id='submitButton'>
					Submit
				</button>
			</div>

		</form>
		</FormProvider>
	);
}

export default CreateUser;