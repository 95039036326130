/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { changePasswordSchema, resetPasswordSchema } from "../Validator";
import { changePassword } from "../../api-wrapper/AuthenticatedApis";
import { useEffect, useState } from "react";
import { resetPassword } from "../../api-wrapper/UserApis";

function ChangePasswordForm() {
	const [pageError, setPageError] = useState('');
	const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
	const location = useLocation();
	const methods = useForm({
		resolver: yupResolver(userId === null ? changePasswordSchema : resetPasswordSchema)
	});

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = methods;

	const onSubmit = (data) => {
		try {
			if (userId === null) {
				changePassword(data).catch(error => setPageError(error?.response?.data));
			} else {
				resetPassword(userId, data);
				navigate('/dashboard')
			}
		} catch (error) {
			setError('confirmNewPassword', { message: error.response.data });
		}
	};

	useEffect(() => {
		if (location.pathname.includes('/dashboard/change-password')) {
			const userId = location.pathname.split('change-password/')[1];
			setUserId(userId);
		}
	}, [location.pathname]);

	useEffect(() => {
        if (pageError !== '')           
			setTimeout(() => {
				setPageError('');
			}, 3500);
    }, [pageError]);

    return (
        <FormProvider {...methods}>
			<form className='change-password-form' onSubmit={handleSubmit(onSubmit)} id='changePasswordForm'>
				<div style={{height:"50px"}}>
					{(pageError !== '') && <div className='page-error' id='pageError'>{pageError}</div>}
				</div>
				{userId === null ? 
				<div className='form-group'>
					<input
						{...register('currentPassword')}
						className='user-box-input'
						type='password'
						placeholder='Enter your current password here'
						id='currentPassword'
					/>
					<div style={{height:"10px"}}>
						{errors.currentPassword && <span className='error' id='currentPasswordError'>{errors.currentPassword.message}</span>}
					</div>
				</div> : <></>}
				<div className='form-group'>
					<input
						{...register('newPassword')}
						type='password'
						className='user-box-input'
						placeholder='Enter your new password here'
						id='password'
					/>
					<div style={{height:"10px"}}>
						{errors.newPassword && <span className='error' id='passwordError'>{errors.newPassword.message}</span>}
					</div>
				</div>
                <div className='form-group'>
					<input
						{...register('confirmNewPassword')}
						type='password'
						className='user-box-input'
						placeholder='Enter and confirm your new password here'
						id='confirmPassword'
					/>
					<div style={{height:"10px"}}>
						{errors.confirmNewPassword && <span className='error' id='confirmPasswordError'>{errors.confirmNewPassword.message}</span>}
					</div>
				</div>
				<br />
				<div>
					<button type='button' className='btn btn-secondary' onClick={e => 
						userId === null ? navigate('/profile') : navigate('/dashboard')} id='backButton'>
						Back
					</button>&nbsp;&nbsp;
					<button type='submit' className='btn btn-primary' id='submitButton'>
						Submit
					</button>
				</div>
			</form>
		</FormProvider>
    );
}

export default ChangePasswordForm;