/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { Button, Modal } from 'react-bootstrap';

const Dialog = ({ show, message, confirmType = true, onCancel, onOk, action = null, data = null }) => {
	return (
		<Modal show={show} onHide={onCancel} id={confirmType ? 'confirmationDialog' : 'alertDialog'}>
			<Modal.Body style={{whiteSpace: 'pre-line'}} id='dialogMessage'>{message}</Modal.Body>
			<Modal.Footer id='dialogActionButtons'>
				{confirmType && 
				<Button variant="secondary" onClick={onCancel} id='dialogCancelButton'>
					Cancel
				</Button>}
				<Button variant="primary" onClick={() => onOk({ action, data })} id='dialogOkButton'>
					Ok
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default Dialog;
