/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { ROLES, COOKIES_TOKEN, DEFAULT_USER } from './common/Variables';
import adminRoutes from './admin/router/routes';
import userRoutes from './user/router/routes';
import reviewerRoutes from './reviewer/router/routes';
import Login from './common/pages/Login';
import MainLayout from './common/pages/MainLayout';
import adminNavbar from './admin/router/nav';
import ForgotPassword from './common/pages/ForgotPassword';
import ResetPassword from './common/pages/ResetPassword';
import RegistrationModal from './common/components/RegistrationModal';
import { jwtDecode } from 'jwt-decode';
import ErrorPage from './common/pages/ErrorPage';
import userNavbar from './user/router/nav';
import reviewerNavbar from './reviewer/router/nav';

function App() {
	const [route, setRoute] = useState([]);
	const token = Cookies.get(COOKIES_TOKEN);
	const [showModal, setShowModal] = useState(false);
	const [role, setRole] = useState(null);
	const [username, setUsername] = useState(null);

	useEffect(() => {
		if (token) {
			const decodedToken = jwtDecode(token);
            setRole(decodedToken.role);
			setUsername(decodedToken.sub);
			
			if (role === ROLES.ADMIN.name) {
				setRoute(adminRoutes);
			} else if (role === ROLES.USER.name) {
				setRoute(userRoutes);
			} else if (role === ROLES.REVIEWER.name) {
				setRoute(reviewerRoutes);
			}
		}
	}, [role, token]);

	function Auth() {

		if (!token) {
            window.location.href = '/';
            return <></>;
        }

		if (role === ROLES.ADMIN.name && username === DEFAULT_USER) {
			setShowModal(true);
			return <></>;
		}
		
		return <MainLayout nav={role === ROLES.ADMIN.name ? adminNavbar : role === ROLES.USER.name ? userNavbar : reviewerNavbar} />
	}

	return (
		<>
			<RegistrationModal show={showModal} />
			<BrowserRouter>
				<Routes>
					{!role && <Route path='/' element={<Login />} />}
					<Route path='/' element={<Auth />}>
						{route?.map((pages, i) => {
							return <Route key={i} path={pages.path} element={pages.component} />;
						})}
					</Route>
					<Route path='/forgot-password' element={<ForgotPassword />} />
					<Route path='/reset-password' element={<ResetPassword />} />
					<Route path='*' element={<ErrorPage />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
