/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import axios from "axios";
import { BASE_URL } from "../common/Variables";
import { refreshApi } from "../service/AxiosInterceptor";

export const loginWithCredentials = async (username, password) => {
	const response = await axios.post(`${BASE_URL}/login`, { username, password });
    return response.data;
};

export const refreshToken = () => {
	return refreshApi.post('/refresh-token');
};

export const forgotPasswordRequest = async (email) => {
	const response = await axios.post(`${BASE_URL}/forgot-password`, email);
    return response.data;
};

export const validateResetPasswordToken = async (paramKey, token) => {
	return await axios.get(`${BASE_URL}/reset-password?${paramKey}=${token}`);
};

export const resetPassword = async (paramKey, token, data) => {
	const response = await axios.post(`${BASE_URL}/reset-password?${paramKey}=${token}`, data);
	return response.data;
};