/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import Cookies from "js-cookie";
import { loginWithCredentials } from "../../api-wrapper/PublicApis";
import { COOKIES_REFRESH_TOKEN, COOKIES_TOKEN, LOGIN_PAGE_SEARCH_PARAM } from "../Variables";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from "../Validator";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function Login() {
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const methods = useForm({
		resolver: yupResolver(loginSchema),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = methods;

	useEffect(() => {
		if (error !== ''){
			setSuccess('');
			setTimeout(() => {
				setError('');
			}, 3500);
		}	
	}, [error]);

	useEffect(() => {
		if (success !== ''){
			setError('');
			setTimeout(() => {
				setSuccess('');
			}, 3500);
		}
	}, [success]);

	useEffect(() => {
		const params = new URLSearchParams(searchParams);

		if (searchParams.get(LOGIN_PAGE_SEARCH_PARAM.Logout) === '') {
			setSuccess('Successfully logged out');
			params.delete(LOGIN_PAGE_SEARCH_PARAM.Logout);
		} else if (searchParams.get(LOGIN_PAGE_SEARCH_PARAM.PasswordChanged) === '') {
			setSuccess('Password successfully updated');
			params.delete(LOGIN_PAGE_SEARCH_PARAM.PasswordChanged);
		} else if (searchParams.get(LOGIN_PAGE_SEARCH_PARAM.AdminCreated) === '') {
			setSuccess('Admin successfully created');
			params.delete(LOGIN_PAGE_SEARCH_PARAM.AdminCreated);
		} else if (searchParams.get(LOGIN_PAGE_SEARCH_PARAM.UserDisabled) === ''){
			setError('Your profile is disabled, Please contact Admin.');
			params.delete(LOGIN_PAGE_SEARCH_PARAM.UserDisabled);
		} else if (searchParams.get(LOGIN_PAGE_SEARCH_PARAM.RecoveryEmailSent) === ''){
			setSuccess('Password recovery mail sent successfully.');
			params.delete(LOGIN_PAGE_SEARCH_PARAM.RecoveryEmailSent);
		}

		setSearchParams(params);
	}, [searchParams, setSearchParams]);

	useEffect(() => {
        if (error !== '')
            setSuccess('');
    }, [error]);

    useEffect(() => {
        if (success !== '')
            setError('');
    }, [success]);

	const [showPassword, setShowPassword] = useState(false);

	const onSubmit = (data) => {
		const { email, password } = data;
		const loginResponse = loginWithCredentials(email, password);

		loginResponse.then((result) => {
			Cookies.set(COOKIES_TOKEN, result.token);
			Cookies.set(COOKIES_REFRESH_TOKEN, result.refreshToken);
			window.location.href = '/';
		}).catch((error) => {
			let message = 'Something went wrong!';
			if (error?.response?.status === 401) {
				message = error.response.data;
			}
			setError(message)
		});
	};

	return (
		<FormProvider {...methods}>
			<form className='mainContainer' onSubmit={handleSubmit(onSubmit)} id='loginForm'>
				<div style={{height:"50px"}}>
					{(error !== '') && <div className='page-error' id='pageError'>{error}</div>}
					{(success !== '') && <div className='page-success' id='pageSuccess'>{success}</div>}
				</div>
				<div className='form-group'>
					<input
						{...register('email')}
						className='user-box-input'
						type='email'
						placeholder='Enter your email here'
						id='email'
					/>
					{errors.email && <span className='error' id='emailError'>{errors.email.message}</span>}
				</div>
				<div className='form-group'>
					<input
						{...register('password')}
						className={`user-box-input ${showPassword ? "show-password" : ""}`}
						type={showPassword ? "text" : "password"}
						placeholder='Enter your password here'
						id='password'
					/>
					{errors.password && <span className='error' id='passwordError'>{errors.password.message}</span>}
					<label className="show-password-label" style={{cursor:'pointer'}} id='showPasswordLabel'>
						<input
							type="checkbox"
							checked={showPassword}
							onChange={() => setShowPassword(!showPassword)}
							id='showPasswordCheckbox'
						/>
						&nbsp;Show Password
					</label>
				</div>
				<button type='submit' className='btn btn-primary' id='submitButton'>
					&nbsp;Login&nbsp;
				</button><br/>
				<a className="forgot-password" onClick={e => navigate('/forgot-password')} id='forgotPasswordLink'>
					Forgot Password?
				</a>
			</form>
		</FormProvider>
	);
}

export default Login;