/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { securedApi } from "../service/AxiosInterceptor";

const base_api = `/receipts`

export const getAllReceipts = async () => {
	const response = await securedApi.get(base_api);
    return response.data;
};

export const getAllApprovedReceipts = async() => {
	const response = await securedApi.get(`${base_api}/get-approved`);
	return response.data;
};

export const getAllReceiptsByUser = async () => {
	const response = await securedApi.get(`${base_api}/get-created`);
	return response.data;
};

export const deleteReceipt = async (id) => {
	try {
		const response = await securedApi.delete(`${base_api}/delete/${id}`);

		if (response.status === 204) {
			return true;
		} else {
			throw new Error('Failed to delete receipt');
		}
	} catch (error) {
		console.error('Error deleting receipt:', error);
		throw error;
	}
};

export const createReceipt = async (data) => {
	const response = await securedApi.post(`${base_api}/save`, data);
    return response.data;
};

export const getReceipt = async (id) => {
	const response = await securedApi.get(`${base_api}/receipt/${id}`);
    return response.data;
};

export const updateReceiptStatus = async (row,status) => {
	
	try {
		const url = `${base_api}/receipt/${status}/update-status`;	
		const response = await securedApi.put(url, row, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		if (response.status === 201) {
			return response.data;
		} else {
			throw new Error('Failed to update receipt status');
		}
	} catch (error) {
		throw(error);
	}
};

export const downloadReceipt = async (id) => {
	const response = await securedApi.get(`${base_api}/download/pdf/${id}`, {
        responseType: 'blob',
      });
	return response.data;
};

export const printReceipt = async (id) => {
	const response = await securedApi.get(`${base_api}/print/pdf/${id}`, {
        responseType: 'blob',
      });
	return response.data;
};

export const emailReceipt = async (id) => {

	try{
		const response = await securedApi.post(`${base_api}/email/pdf/${id}`);	
		if (response.status === 201) {
			return response.data;
		} else {
			throw new Error('Failed to update receipt status');
		}
	}
	catch(error){
		throw error;
	}
	
};