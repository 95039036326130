/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCompanyProfile } from "../../api-wrapper/CompanyProfileApis";

function CompanyProfile() {
	const navigate = useNavigate();
	const [profile, setProfile] = useState({});
	const [imageData,setImageData] = useState(null);

	useEffect(() => {
		getCompanyProfile().then(
			response =>{
				setProfile(response);
				const base64String = `data:image/png;base64,`+ response['logo'];
				setImageData(base64String);
			});
		},
	[]);

	return (
		<div className='user-info-card' id='companyProfilePage'>
			<h3 id='companyProfileTitle'>Company Profile</h3>
			<br />
			{profile && (
				<>
					<div id='companyProfileDetails'>
						<p id='panNumber'><strong>PAN Number:</strong> {profile.panNumber}</p>
						<p id='regNumber'><strong>Reg. Number:</strong> {profile.regNumber}</p>
						<p id='organizationName'><strong>Organization Name:</strong> {profile.organizationName}</p>
						<p id='email'><strong>Email:</strong> {profile.email}</p>
						<p id='address'><strong>Address:</strong> {profile.address}</p>
						<p id='isSignatureRequired'><strong>Signature Required:</strong> {profile.signatureRequired ? 'True' : 'False'}</p>
						<p id='file'><strong>Logo:</strong> <img width="75" height="75" src={imageData}/> </p>
					</div>
					<br />
				</>
			)}
			
			<button className={`btn ${profile ? 'btn-outline-secondary' : 'btn-outline-primary'}`} onClick={e => navigate('/company-profile/save-profile')} 
				id = { profile ? 'editProfileButton' : 'createProfileButton' }>
				{ profile ? 'Edit Profile' : 'Create Profile' }
			</button>
		</div>
	);
};

export default CompanyProfile;